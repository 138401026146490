<template>

  <div>

    <div class="container m-auto">

      <template class="lg-scr-only">

        <header class="flex flex-row mx-4">

          <router-link to="/"><div class="logo"></div></router-link>

          <div id="nav" class="pt-8">

            <div class="mb-2">

              <a href="tel:2624322365">

                <i class="pr-2 text-xl fas fa-phone-alt" />

                <span class="text-2xl font-extrabold">(262) 432-2365</span>

              </a>

              <br />

              <a href="mailto:info@pomcs.com">

                <i class="pr-2 text-xl fas fa-envelope contactus" />

                <span class="text-2xl font-extrabold contactus">
                   Contact Us
                </span>

              </a>

            </div>

            <router-link to="/">Home</router-link>

            <router-link to="/employment-application">Employment</router-link>

            <!-- <router-link to="/purchase-service">Purchase Service</router-link> -->

            <router-link to="/contact-us">Contact</router-link>

            <!-- <router-link to="/admin">Admin</router-link> -->

          </div>

        </header>

      </template>

      <header class="mx-4 sm-scr-only">

        <div class="mx-auto logo"></div>

        <div id="nav" class="pt-none">

          <div class="mb-2">

            <a href="tel:2624322365">

              <i class="pr-2 text-xl fas fa-phone-alt" />

              <span class="text-2xl font-extrabold">(262) 432-2365</span>

            </a>

            <br />

            <a href="mailto:info@pomcs.com">

              <i class="pr-2 text-xl fas fa-envelope contactus" />

              <span class="text-2xl font-extrabold contactus">Contact Us</span>

            </a>

          </div>

          <router-link to="/">Home</router-link>

          <router-link to="/employment-application">Employment</router-link>

          <!-- <router-link to="/purchase-service">Purchase Service</router-link> -->

          <router-link to="/contact-us">Contact</router-link>

          <!-- <router-link to="/admin">Admin</router-link> -->

        </div>

      </header>

      <div class="flex items-center justify-between p-4">

        <router-view />

      </div>

      <div class="grid grid-cols-1 gap-3 p-4 sm:grid-cols-2">

        <div
          class="p-6 mb-3 text-white border rounded-lg shadow-lg border-gray callout"
        >

          <!-- <p>Peace of Mind Cleaning &amp; Concierge Service</p> -->

          <p class="mb-4 text-lg font-bold">

            <a href="tel:2624322365">

              <i class="pr-2 text-xl fas fa-phone-alt" />

              <span class="text-2xl font-extrabold">(262) 432-2365</span>

            </a>

          </p>

          <p>

            <i class="pr-2 text-xl fas fa-map-marker-alt" />
             W176 N9830 Rivercrest Drive, Suite 102
            <br />
             Germantown, WI 53022
          </p>

        </div>

        <div
          class="flex items-center p-6 mb-3 text-white border rounded-lg shadow-lg justify-items-center border-gray callout"
        >
           Serving the Milwaukee Metropolitan area: Milwaukee, Ozaukee, Washington,
          and Waukesha counties.
        </div>

      </div>

      <footer class="px-4 py-8">

        <div>&copy; 2021 Peace of Mind Cleaning &amp; Concierge Service</div>

        <div class="socialicons">

          <a
            target="_blank"
            href="http://www.facebook.com/pages/Peace-of-Mind-Concierge-Service/325834570251"
            class="socialicon icon_facebook"
            title="Linked In"
            style="opacity: 1"
          >

            <img
              src="~@/assets/images/icon_facebook_light.png"
              alt="facebook icon"
            />

          </a>

          <a
            target="_blank"
            href="https://twitter.com/POMCS_Milwaukee"
            class="socialicon icon_twitter"
            title="Linked In"
            style="opacity: 1"
          >

            <img
              src="~@/assets/images/icon_tweet_light.png"
              alt="facebook icon"
            />

          </a>

          <a
            target="_blank"
            href="http://www.linkedin.com/company/peace-of-mind-concierge-service?trk=prof-following-company-logo"
            class="socialicon icon_linkedin"
            title="Linked In"
            style="opacity: 1"
          >

            <img
              src="~@/assets/images/icon_linkedin_light.png"
              alt="facebook icon"
            />

          </a>

        </div>

      </footer>

    </div>

  </div>

</template>

<script>
 import { useRouter, useRoute } from "vue-router"; import { onBeforeMount, provide,
ref } from "vue"; import firebase from "firebase"; export default { provide: { global
}, setup() { const router = useRouter(); const route = useRoute(); const user = ref({});
onBeforeMount(() => { firebase.auth().onAuthStateChanged((userInfo) => { if (route.path
== "/login" || route.path == "/register") { user.value = userInfo; router.replace("/");
} }); }); return { global, user, }; }, };
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #777;

  input,
  select,
  textarea {
    color: #000000;
  }

  input[type="submit"] {
    color: inherit;
  }
}

#nav {
  a {
    margin-right: 10px;
    font-weight: normal;
    color: #265aa9;

    &.router-link-exact-active {
      color: #e9a025;
    }
  }
}
footer,
header,
.info {
  display: flex;
  justify-content: space-between;
}
.socialicons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.contactus {
  color: #88bfe8;
}
.callout {
  background-color: #88bfe8;
}
.sm-scr-only {
  @media screen and (max-width: 767px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.lg-scr-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>

