import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyDXJM2SDnN1p64PAvLt7Oe88goUHiFu-x4",
  authDomain: "pomcs-com.firebaseapp.com",
  projectId: "pomcs-com",
  storageBucket: "pomcs-com.appspot.com",
  messagingSenderId: "285010046726",
  appId: "1:285010046726:web:373758dc559e58cce13887",
}

const firebaseApp = firebase.initializeApp(config)

let isAuthenticated = "unauthorized"

firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    isAuthenticated = "authorized"
  } else {
    isAuthenticated = "unauthorized"
  }
})

const login = (email, password) => {
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((data) => console.log("login user data:", data))
    .catch((err) => alert(err.message))
}

const register = (email, password) => {
  firebaseApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => console.log("register user data:", user))
    .catch((err) => alert(err.message))
}

const logout = () => {
  firebaseApp
    .auth()
    .signOut()
    .then(console.log("User logged out."))
    .catch((err) => alert(err.message))
}

const getUserName = () => {
  const user = firebaseApp.auth().currentUser
  let name = ""
  if (user) {
    name = user.email.split("@")[0]
    return name
  } else {
    return name
  }
}

const db = firebaseApp.firestore()

const getAllOfPageType = async (page, type) => {
  const arrayData = []
  const snapshot = await db
    .collection("dynamic-items")
    .where("page", "==", page)
    .where("type", "==", type)
    .orderBy("sortOrder")
    .get()
  snapshot.forEach((doc) => {
    arrayData.push(doc.data())
  })
  const postData = {}
  postData[type] = arrayData
  const result = postData[type]
  return result
}

export {
  db,
  isAuthenticated,
  login,
  register,
  logout,
  getUserName,
  getAllOfPageType,
}
